import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import OpenInNewIcon from '@material-ui/icons/OpenInNew';
export const _frontmatter = {
  "title": "Production disposition by month",
  "includeToc": true,
  "layout": "downloads",
  "description": "monthly information on the disposition of production for each product (oil and gas) produced.",
  "tags": ["Data", "Downloads", "Documentation", "Native American", "Indian", "Federal", "Production", "disposition", "OGOR B", "oil", "gas"]
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><a parentName="p" {...{
        "href": "/downloads/"
      }}>{`Downloads /`}</a></p>

    <h1 {...{
      "id": "Production-disposition-by-month",
      "style": {
        "position": "relative"
      }
    }}>{`Production disposition by month`}</h1>
    <p>{`This dataset contains monthly production disposition volumes from ONRR’s OGOR-B form. These volumes come from the production of oil and gas. Production locations include federal lands, federal waters, and Native American lands.`}</p>
    <br />
    <p>{`This data is available from January 2003 through the most recently available month, which is usually 3-4 months prior to the current month. The historical dataset contains data from January 2003 through December 2013 and is not updated. The dataset starting with January 2014 is updated monthly.`}</p>
    <br />
    <p>{`This dataset contains monthly information on the disposition of production for each product (oil and gas) produced. The product could have been:`}</p>
    <ul>
      <li parentName="ul">{`directly removed/sold`}</li>
      <li parentName="ul">{`directly used (injected)`}</li>
      <li parentName="ul">{`moved off the lease`}</li>
      <li parentName="ul">{`transferred to a different facility`}</li>
      <li parentName="ul">{`flared, spilled and/or lost`}</li>
      <li parentName="ul">{`lost through evaporation/shrinkage`}</li>
    </ul>
    <p>{`Download production disposition data by month:`}</p>
    <Link linkType="DownloadCsv" href="/downloads/OGOR-B/OGORBcsv.csv" mdxType="Link">
  Production Disposition Volumes, January 2014 - July 2024
    </Link>
    <Link linkType="DownloadXls" href="/downloads/OGOR-B/OGORB.xlsx" mdxType="Link">
  Production Disposition Volumes, January 2014 - July 2024
    </Link>
    <h2 {...{
      "id": "Download-historical-production-disposition-data-by-month",
      "style": {
        "position": "relative"
      }
    }}>{`Download historical production disposition data by month:`}</h2>
    <Link linkType="DownloadCsv" href="/downloads/OGOR-B/Historical_OGORBcsv_CY2003-2013.csv" mdxType="Link">
  Historical Production Disposition Volumes, January 2003 - December 2013
    </Link>
    <Link linkType="DownloadXls" href="/downloads/OGOR-B/Historical_OGORB_CY2003-2013.xlsx" mdxType="Link">
  Historical Production Disposition Volumes, January 2003 - December 2013
    </Link>
    <h2 {...{
      "id": "Scope",
      "style": {
        "position": "relative"
      }
    }}>{`Scope`}</h2>
    <p>{`This dataset includes oil and gas production for U.S. federal lands, federal waters, and Native American lands. This dataset does not include privately owned minerals or U.S. state minerals. Federal production data is available by location. Native American data is only available at the national level to protect private and personally identifiable information.`}</p>
    <br />
The Office of Natural Resources Revenue (ONRR) manages this dataset. The production disposition data for oil and gas is collected on Form ONRR-4054-B (Oil and Gas Operations Report, Part B).
    <br />
    <h2 {...{
      "id": "Data-publication",
      "style": {
        "position": "relative"
      }
    }}>{`Data publication`}</h2>
    <p>{`We update this production data every month, but final production numbers aren't available for 3-4 months. For example, the most recent production data for a file updated in November will be from July or August.`}</p>
    <h2 {...{
      "id": "About-the-data",
      "style": {
        "position": "relative"
      }
    }}>{`About the data`}</h2>
    <p>{`A note about “mixed exploratory” versus “federal” production categories. To build our data visualizations, production was combined from two types of jurisdictions: “federal” and “mixed exploratory.”`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Federal`}</strong>{` is production from federal onshore and offshore minerals.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Mixed Exploratory`}</strong>{` is a temporary jurisdictional unit. It is used until production is proven on that location. It comprises federal and/or Native American minerals, with other mineral ownership. Utilizing “mixed exploratory” helps drilling and production proceed in the most efficient and economical manner.`}</p>
      </li>
    </ul>
    <h2 {...{
      "id": "Data-dictionary",
      "style": {
        "position": "relative"
      }
    }}>{`Data dictionary`}</h2>
    <h3 {...{
      "id": "Fields-and-definitions",
      "style": {
        "position": "relative"
      }
    }}>{`Fields and definitions`}</h3>
    <p><strong parentName="p">{` Production Date:`}</strong>{`  The date in which the production occurred.`}</p>
    <br />
    <p><strong parentName="p">{`Land Class:`}</strong>{`  This field distinguishes federal lands and waters from Native American lands.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Federal:`}</strong>{`  Includes `}<a parentName="li" {...{
          "href": "https://sgp.fas.org/crs/misc/R42346.pdf"
        }}>{`federal lands (PDF) `}<OpenInNewIcon style={{
            fontSize: 20
          }} mdxType="OpenInNewIcon" /></a>{` that are owned by or under the jurisdiction of the federal government. Federal ownership includes public domain, acquired, military acquired, and  `}<a parentName="li" {...{
          "href": "https://www.boem.gov/oil-gas-energy/leasing/outer-continental-shelf"
        }}>{`Outer Continental Shelf `}<OpenInNewIcon style={{
            fontSize: 20
          }} mdxType="OpenInNewIcon" /></a>{`.`}</li>
      <li parentName="ul"><strong parentName="li">{`Native American:`}</strong>{`  Includes tribal lands held in trust by the federal government for a tribe’s use. It also includes allotments held in trust by the federal government for individual Native American use.`}</li>
      <li parentName="ul"><strong parentName="li">{`Mixed Exploratory:`}</strong>{` A temporary jurisdictional unit that is used until production is proven on that location (see above).`}<br /></li>
    </ul>
    <p><strong parentName="p">{`Land Category:`}</strong>{`  This field distinguishes between onshore and offshore production.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Onshore:`}</strong>{`  Situated or occurring on land.`}</li>
      <li parentName="ul"><strong parentName="li">{`Offshore:`}</strong>{`  Submerged lands located farther than three miles off a state’s coastline. For the Gulf of Mexico, offshore is three marine leagues from Texas and Western Florida.`}<br /></li>
    </ul>
    <p><strong parentName="p">{`State:`}</strong>{` The state where the production occurred.`}</p>
    <br />
    <p><strong parentName="p">{`County:`}</strong>{` The county where the production occurred.`}</p>
    <br />
    <p><strong parentName="p">{`FIPS Code:`}</strong>{` The Federal Information Processing Standard (FIPS) code is a five-digit code which uniquely identifies counties and county equivalents in the U.S., certain U.S. possessions, and certain freely associated states. The first two digits are the FIPS state code and the last three are the county code within the state or possession.`}</p>
    <br />
    <p><strong parentName="p">{`Offshore Region: `}</strong>{` BOEM separates offshore areas into four regions: Gulf of Mexico, Atlantic, Pacific, and Alaska. For more information on offshore regions, including spatial boundaries, see BOEM's `}<a parentName="p" {...{
        "href": "https://www.boem.gov/oil-gas-energy/mapping-and-data"
      }}>{`maps and GIS data `}<OpenInNewIcon style={{
          fontSize: 20
        }} mdxType="OpenInNewIcon" /></a>{`.`}</p>
    <br />
    <p><strong parentName="p">{`Disposition Code:`}</strong>{` This field is a two-digit code indicating the means of product removal from the report entity.`}</p>
    <br />
    <p><strong parentName="p">{`Disposition Description:`}</strong>{` This field describes how the oil or gas is used and/or where it goes.`}</p>
    <br />
    <p><strong parentName="p">{`Dispositions associated with oil:`}</strong></p>
    <br />
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "center"
          }}><strong parentName="th">{`Disposition Code`}</strong></th>
          <th parentName="tr" {...{
            "align": "left"
          }}></th>
          <th parentName="tr" {...{
            "align": "left"
          }}><strong parentName="th">{`Disposition Description `}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`01`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Sales - Royalty Due - MEASURED`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`03`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Load Oil`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`04`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Sales - Royalty Due - Not Measured`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`05`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Sales - Royalty Not Due, Recovered Injection - MEASURED`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`07`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Condensate Sales - Royalty Due - MEASURED`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`08`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Spilled and/or Lost - Avoidable - Royalty Due`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`09`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Sales - Royalty Not Due - MEASURED`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`10`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Produced into Inventory Prior to Sales`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`11`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Transferred to Facility`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`13`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Transferred from Facility`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`14`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Injected on L/A`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`15`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Sales Buy-Back - Measured - Royalty Not Due`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`16`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Pipeline Drip/Retrograde Scrubber Production`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`20`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Used on L/A - Native Production Only`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`23`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Spilled and/or Lost - Unavoidable - Royalty Not Due`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`24`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Theft`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`25`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Buy-Back Purchased for L/A Use`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`26`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Buy-Back - Used on L/A`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`29`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Waste Oil/Slop Oil`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`42`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Differences/Adjustments`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`43`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Sales - Royalty Not Due - FMP not Assigned`}</td>
        </tr>
      </tbody>
    </table>
    <br />
    <p><strong parentName="p">{`Dispositions associated with gas:`}</strong></p>
    <br />
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "center"
          }}><strong parentName="th">{`Disposition Code`}</strong></th>
          <th parentName="tr" {...{
            "align": "left"
          }}></th>
          <th parentName="tr" {...{
            "align": "left"
          }}><strong parentName="th">{`Disposition Description `}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`01`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Sales - Royalty Due - MEASURED`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`04`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Sales - Royalty Due - Not Measured`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`05`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Sales - Royalty Not Due - Recovered Injection - MEASURED`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`06`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Sales - Non-Hydrocarbon Gas`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`08`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Spilled and/or Lost - Avoidable - Royalty Due`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`09`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Sales - Royalty Not Due - MEASURED`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`11`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Transferred to Facility`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`12`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Transferred to Facility - Returned to L/A`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`13`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Transferred from Facility`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`14`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Injected on L/A`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`15`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Sales Buy-Back - Measured - Royalty Not Due`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`20`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Used on L/A - Native Production Only`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`21`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Flared Oil - Well Gas`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`22`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Flared Gas - Well Gas`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`23`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Spilled and/or Lost - Unavoidable - Royalty Not Due`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`24`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Theft`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`25`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Buy-Back - Purchased for L/A Use`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`26`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Buy-Back - Used on L/A`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`33`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Flared Gas - Royalty Due`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`42`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Differences/Adjustments`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`43`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Sales - Royalty Not Due - FMP Not Assigned`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`61`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Vented Oil - Well Gas`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`62`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Vented Gas - Well Gas`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}>{`63`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Vented Gas - Royalty Due`}</td>
        </tr>
      </tbody>
    </table>
    <br />
    <p><strong parentName="p">{`Commodity:`}</strong>{` Products are listed individually but may also fall into like categories.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Oil Prod Vol (bbl):`}</strong>{`  Oil production, measured in barrels (bbl).`}</li>
      <li parentName="ul"><strong parentName="li">{`Gas Prod Vol (Mcf):`}</strong>{`  Gas (natural gas), measured in thousand cubic feet (Mcf).`}</li>
    </ul>
    <p><strong parentName="p">{`Volume:`}</strong>{`  Shows the production volume (amount produced). The units for volume are shown in the preceding field (Commodity).`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      